<template>
  <div class="bg bg4">
    <div class="class--con">
      <class-but @forward="forward"/>
      <div class="class--con__title">知识点学习回顾</div>
      <div class="class--container" v-if="courseKngList">
        <div>
          <div style="font-size: 24px;color:#2B3654;margin-bottom: 39px">截止到本堂课、本学期一共学习了<span
              style="color:#FC795B;">{{ courseKngList.kngSize }}个</span>知识点！
          </div>
          <div class="class--con__info" v-if="courseKngList.kngList.length > 1">
            <div class="class--con__list" v-for="(i,k) in courseKngList.kngList" :key="k">
              <span style=" color: #666666;font-size: 24px;">{{ i.var1 }}</span>
              <span class="class--tag">{{ i.var2 }}个</span>
            </div>
          </div>
          <div class="randomordering">
            <span v-for="(i,k) in uniwque(courseKngList.kngNames)" :key="k" class="randomordering--tag">{{ i }}</span>
          </div>
        </div>
        <div style="display: flex;align-items: center;flex-direction: column">
          <div style="color: #2B3654;font-size: 40px">代表作品</div>
          <div class="class--container__img">
            <img :src="courseKngList.imgUrl" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";

export default {
  name: "fivePage",
  components: {ClassBut},
  data() {
    return {
      marginLeft: null,
      courseKngList: null
    }
  },
  created() {
    this.gitApicourseKng()
  },

  methods: {
    forward() {
      this.$router.push({
        name: 'PageFourteenth',
        query: this.$route.query,
      })
    },

    //去重
    uniwque(arr) {
      return Array.from(new Set(arr))
    },
    async gitApicourseKng() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/courseKng`, {
        classId: this.$route.query.classId,
      })
      this.courseKngList = res
    },
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";


.class--container {
  padding: 0px 90px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  /*div:nth-child(1){*/
  /*  margin-bottom: 39px;*/
  /*}*/
}

.class--container__img {
  width: 1017px;
  height: 600px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #FF9900;

  img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }
}

.class--con__info {
  /*height: 200px;*/
  width: 490px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .class--con__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .class--tag {
      width: 100px;
      height: 40px;
      display: inline-block;
      background: #FC795B;
      border-radius: 23px;
      text-align: center;
      line-height: 40px;
      color: #ffffff;
      font-size: 24px;
    }
  }
}

.randomordering {
  width: 490px;
  height: 420px;
  overflow-y: auto;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .randomordering--tag {
    margin-bottom: 10px;
    /*width: 179px;*/
    max-width: 205px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
    height: 50px;
    border-radius: 35px;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
    font-size: 23px;
    display: inline-block;
    user-select: none;
  }
  .randomordering--tag:nth-child(odd){
    background: #FEA775;
  }
  .randomordering--tag:nth-child(even){
    background: #FFB537;
  }
}

::-webkit-scrollbar {
  display: none;
}
</style>
